import React from 'react';
import {
  ActiveCampaignInputs,
  handleActiveCampaignSubmit,
} from 'active-campaign-react';
import { useForm } from 'react-hook-form';
import './styles.css';
import { useState } from 'react';
export function Form() {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const { register, handleSubmit } = useForm();
  const formId = '40';

  const onSubmit = async (data) => {
    setLoading(true);
    const res = await handleActiveCampaignSubmit(data, 'flaviobabos', formId);
    setLoading(false);
    if (res.status === 0) {
      setSuccess(true);
    }
  };

  return (
    <div className="form-card ">
      <h3>[Exclusivo] Vouchers da AWS. Preencha para concorrer 🔥</h3>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ActiveCampaignInputs formId={formId} />

        <input
          name="fullname"
          id="fullname"
          {...register('fullname', { required: true })}
          placeholder="Seu nome"
        />
        <input
          name="email"
          type="email"
          id="email"
          {...register('email', { required: true })}
          placeholder="Seu melhor email"
        />
        {success && (
          <>
            <div>
              <p className="success">
                Obrigada! Fique de olho na sua caixa de entrada que em até 5
                minutos você receberá a confirmação no seu email 👀
              </p>
            </div>
          </>
        )}
        {/* <div className="button"> */}
        <button type="submit">{loading ? 'Enviando...' : 'Enviar'}</button>
        {/* </div> */}
      </form>
    </div>
  );
}
