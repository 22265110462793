import React from 'react';

import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { CursoGithub } from '../pages/CursoGithub';
import { CursoGithub2 } from '../pages/CursoGithub2';
import { DevStart } from '../pages/DevStart';
import { DevStartRedirect } from '../pages/DevStartRedirect';
import { Home } from '../pages/Home';
import { ApprovedPurchase } from '../pages/ApprovedPurchase';
import { Voucher } from '../pages/Voucher';

export function Router(props) {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={`/`} Component={Home} />
        <Route path={`/dev-start`} Component={DevStart} />
        <Route
          path={`/dev-start-cupom-desconto-sejabemvindo`}
          Component={DevStartRedirect}
        />
        <Route path={`/dev-start-parabens`} Component={ApprovedPurchase} />
        <Route path={`/vouchers-aws-parabens`} Component={Voucher} />
        <Route
          path={`/workshop-git-github/7b753893-fc4c-4032-a0f9-576e078bb995`}
          Component={CursoGithub}
        />
        <Route
          path={`/workshop-git-github/b635eb17-2c4a-4f6d-b94a-fd37ca49ac18`}
          Component={CursoGithub2}
        />
      </Routes>
    </BrowserRouter>
  );
}
