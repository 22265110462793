import { Helmet } from 'react-helmet';
import gmail from '../../assets/gmail.png';
import outlook from '../../assets/outlook.png';
import yahoo from '../../assets/yahoo.png';
import foto_perfil from '../../assets/foto-perfil.png';

export function Voucher() {
  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <div className="container text-center">
        <lord-icon
          src="https://cdn.lordicon.com/tqywkdcz.json"
          trigger="loop"
          delay="200"
          style={{ width: '250px', height: '250px' }}
        ></lord-icon>
        <h1>Parabéns!</h1>
        <br />
        <br />
        <p className="congratulation">
          Você acaba de confirmar sua inscrição para receber o voucher de 50% de
          desconto para a certificação da AWS!
        </p>
        <p className="congratulation">
          Irei analisar quais foram os 50 primeiros que se inscreveram e vou
          encaminhar um email para os ganhadores.
        </p>
        <p className="congratulation">
          Então, fique atento tanto no e-mail como no meu perfil do Instagram!
        </p>
        <br />
        <br />
        {/* <div className="d-flex justify-content-around mb-5">
          <div
            className="email"
            onClick={() =>
              (window.location.href = 'https://mail.google.com/mail/u/0/')
            }
          >
            <img src={gmail} alt="" />
            <p>Acessar Gmail</p>
          </div>
          <div
            className="email"
            onClick={() =>
              (window.location.href = 'https://outlook.live.com/mail/0/')
            }
          >
            <img src={outlook} alt="" />
            <p>Acessar Outlook</p>
          </div>
          <div
            className="email"
            onClick={() => (window.location.href = 'https://mail.yahoo.com')}
          >
            <img src={yahoo} alt="" />
            <p>Acessar Yahoo</p>
          </div>
        </div> */}
        <div className="m-auto d-flex profile-photo">
          <a
            href="https://instagram.com/alexiakattah"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={foto_perfil} alt="" width={'60px'} />
          </a>
          <div>
            <p>Alexia Kattah</p>
            <p>@alexiakattah</p>
          </div>
        </div>
      </div>
    </>
  );
}
