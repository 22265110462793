import 'aos/dist/aos.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'moment/locale/pt-br';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';

import './styles.css';
export function DevStart() {
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [open5, setOpen5] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [btn, setButton] = useState('');
  const [campain, setCampain] = useState(false);
  const [success, setSuccess] = useState(false);
  const { register, handleSubmit } = useForm();
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  const src = searchParams.get('src');
  var formId = '24';
  if (src === 'fads-campaignfria') {
    formId = 30;
  }
  console.log('🚀 ~ file: index.jsx:49 ~ src:', src);

  useEffect(() => {
    //redirecionar para herocode.com.br
    window.location.href =
      'https://herocode.com.br/dev-start?utm_source=redirect&utm_medium=link&utm_campaign=dev-start';

    // AOS.init({
    //   once: true,
    // });
    // const url = window.location.href;
    // const res = url.split('?');

    // if (res[1] !== undefined) {
    //   var id = res[1] && res[1].split('=');
    //   if (id[0] === 'src') setCampain(id[1]);
    // }
    // moment.locale('pt-br');
  }, []);

  // const videoEl = useRef(null);

  // const attemptPlay = () => {
  //   videoEl &&
  //     videoEl.current &&
  //     videoEl.current.play().catch((error) => {
  //       console.error('Error attempting to play', error);
  //     });
  // };

  // // useEffect(() => {
  // //   attemptPlay();
  // // }, []);

  // const date1 = moment(Date()).format('DD/MM');

  // const date2 = moment(Date()).subtract(1, 'days').format('DD/MM');
  // const date3 = moment(Date()).subtract(2, 'days').format('DD/MM');
  // const date4 = moment().format('MMMM');

  // function closeModal() {
  //   setModalIsOpen(false);
  // }
  // const onSubmit = async (data) => {
  //   setLoading(true);
  //   const res = handleActiveCampaignSubmit(data, 'flaviobabos', formId);
  //   console.log('🚀 ~ file: index.jsx:95 ~ onSubmit ~ res:', res);
  //   setLoading(false);
  //   setSuccess(true);
  //   window.location.href = `https://pay.hotmart.com/P73855232F?checkoutMode=10&name=${data.fullname}&email=${data.email}&sck=${campain}&utm_medium=${btn}`;

  //   // return;
  //   // if (res.status === 0) {
  //   //   setSuccess(true);
  //   //   window.location.href = `https://pay.hotmart.com/P73855232F?checkoutMode=10&name=${data.fullname}&email=${data.email}&sck=${campain}&utm_medium=${btn}`;
  //   // }
  // };

  // function handleModal(button) {
  //   setButton(button);
  //   window.location.href = `https://pay.hotmart.com/P73855232F?checkoutMode=10&sck=${campain}&utm_medium=${btn}`;
  // }
  // function modal() {
  //   return (
  //     <>
  //       <Modal
  //         isOpen={modalIsOpen}
  //         onRequestClose={closeModal}
  //         contentLabel="Example Modal"
  //         id="modal_confirm_action"
  //         className=""
  //       >
  //         <div className="container p-4 m-auto  ">
  //           <div className=" m-auto justify-content-center ">
  //             <h3>Vamos iniciar o seu cadastro</h3>
  //             <p>
  //               Digite seu Nome, E-mail que você utilizará para acessar o
  //               Conteúdo Fechado.
  //             </p>
  //             <form onSubmit={handleSubmit(onSubmit)}>
  //               <ActiveCampaignInputs formId={formId} />

  //               <input
  //                 name="fullname"
  //                 id="fullname"
  //                 {...register('fullname', { required: true })}
  //                 placeholder="Seu nome"
  //               />
  //               <input
  //                 name="email"
  //                 type="email"
  //                 id="email"
  //                 {...register('email', { required: true })}
  //                 placeholder="Seu melhor email"
  //               />

  //               {/* <div className="button"> */}
  //               <button type="submit">
  //                 {loading ? 'Aguarde...' : 'Continuar'}
  //               </button>
  //               {/* </div> */}
  //             </form>
  //           </div>
  //         </div>
  //         <div className="d-flex justify-content-center">
  //           <img src={cartao} alt="" width={'300px'} />
  //         </div>
  //       </Modal>
  //     </>
  //   );
  // }

  // // const second = 1000;
  // // const minute = second * 60;
  // // const hour = minute * 60;
  // // const day = hour * 24;

  // // let count_down = new Date('09/25/2022 12:00:00').getTime();
  // // let x = setInterval(() => countDown(), second);
  // // function addZeroes(num, len) {
  // //   var numberWithZeroes = String(num);
  // //   var counter = numberWithZeroes.length;

  // //   while (counter < len) {
  // //     numberWithZeroes = '0' + numberWithZeroes;

  // //     counter++;
  // //   }

  // //   return numberWithZeroes;
  // // }
  // // function countDown() {
  // //   let now = new Date(Date.now()).getTime();
  // //   let diff = count_down - now;

  // //   document.getElementById('days').innerText = addZeroes(
  // //     Math.floor(diff / day),
  // //     1,
  // //   );
  // //   document.getElementById('hours').innerText = Math.floor(
  // //     (diff % day) / hour,
  // //   );
  // //   document.getElementById('minutes').innerText = Math.floor(
  // //     (diff % hour) / minute,
  // //   );
  // //   document.getElementById('seconds').innerText = Math.floor(
  // //     (diff % minute) / second,
  // //   );
  // // }

  // return (
  //   <>
  //     <Helmet>
  //       <title>Ebook Dev Start - Programação</title>
  //       <meta content="Aprenda programação do zero" name="description" />
  //     </Helmet>
  //     {/* <div id="timer">
  //       <div className="timer-promocao">
  //         <p className="cupom">
  //           Cupom de Lançamento <strong>20OFF</strong>{' '}
  //         </p>
  //         <p>
  //           Essa promoção expira <strong>Domingo (25/09) MEIO DIA</strong>{' '}
  //         </p>
  //       </div>
  //       <div>
  //         <p id="days"></p>
  //         <p>Dias</p>
  //       </div>
  //       <div>
  //         <p id="hours"></p>
  //         <p>Horas</p>
  //       </div>
  //       <div>
  //         <p id="minutes"></p>
  //         <p>Minutos</p>
  //       </div>
  //       <div>
  //         <p id="seconds"></p>
  //         <p>Segundos</p>
  //       </div>
  //     </div> */}

  //     <div className="first-section">
  //       <nav className="container navbar navbar-expand-lg px-3">
  //         <div className="container-fluid  justify-content-between">
  //           <div>
  //             <img
  //               className="image-transparent-background"
  //               src={logo}
  //               alt=""
  //               width="200px"
  //               data-aos="zoom-in"
  //               data-aos-duration="2000"
  //             />
  //           </div>
  //           <button
  //             className="navbar-toggler"
  //             type="button"
  //             data-bs-toggle="collapse"
  //             data-bs-target="#navbarNavAltMarkup"
  //             aria-controls="navbarNavAltMarkup"
  //             aria-expanded="false"
  //             aria-label="Toggle navigation"
  //           >
  //             <span className="material-symbols-sharp">menu</span>
  //           </button>
  //           <div
  //             className="collapse navbar-collapse justify-content-end"
  //             id="navbarNavAltMarkup"
  //           >
  //             <div className="navbar-nav">
  //               <div className="mr-3">
  //                 <a className="navbar-options nav-link " href="#aprendizado">
  //                   O que você vai aprender
  //                 </a>
  //               </div>
  //               <div className="mr-3">
  //                 <a className="navbar-options nav-link" href="#capitulos">
  //                   Capítulos
  //                 </a>
  //               </div>
  //               <div className="mr-3">
  //                 <a className="navbar-options nav-link" href="#bonus">
  //                   Bônus
  //                 </a>
  //               </div>
  //               <div className="mr-3">
  //                 <a className="navbar-options nav-link" href="#faq">
  //                   Perguntas frequentes
  //                 </a>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       </nav>

  //       <div className="container pt-5 col-12 section1">
  //         <h5
  //           className="text-center pb-3 first-text"
  //           data-aos="zoom-in"
  //           data-aos-duration="2000"
  //         >
  //           <strong>
  //             O E-book para você aprender programação do zero e conseguir criar
  //             seus primeiros projetos WEB em apenas 4 semanas!
  //           </strong>
  //         </h5>
  //         <div
  //           className="video-container video-teaser-horizontal container col-12 col-md-12 m-auto"
  //           data-aos="fade-up"
  //         >
  //           <iframe
  //             src="https://www.youtube-nocookie.com/embed/GGvpK686LSU"
  //             title="YouTube video player"
  //             frameborder="0"
  //             className=""
  //             allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
  //             allowfullscreen
  //           ></iframe>
  //         </div>
  //         <div
  //           className="video-container container col-12 col-md-12 m-auto video-teaser w-auto"
  //           data-aos="fade-up"
  //         >
  //           <iframe
  //             src="https://www.youtube-nocookie.com/embed/FUSdB5nGvN4"
  //             title="YouTube video player"
  //             frameborder="0"
  //             className=""
  //             allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
  //             allowfullscreen
  //           ></iframe>
  //         </div>
  //         <div>
  //           {/* <div className="col-md-12 m-auto col-12 text-center ">
  //             <div className="mockup">

  //               <video
  //                 width="100%"
  //                 loop={false}
  //                 autoPlay={false}
  //                 controls
  //                 className="video-teaser-horizontal abs   text-center m-auto"
  //                 ref={videoEl}
  //               >
  //                 <source src={horizontal} type="video/mp4" />
  //                 Your browser does not support HTML video.
  //               </video>
  //             </div>

  //             <div className="video-container-teaser container text-center m-auto">

  //               <video
  //                 width="400"
  //                 loop={false}
  //                 autoPlay={false}
  //                 controls
  //                 className="video-teaser text-center m-auto"
  //                 ref={videoEl}
  //               >
  //                 <source src={vertical} type="video/mp4" />
  //                 Your browser does not support HTML video.
  //               </video>
  //             </div>
  //           </div> */}
  //           <div className="col-md-12  col-12 m-auto  mt-4 ">
  //             <h5
  //               className="text-center first-text"
  //               data-aos="zoom-in"
  //               data-aos-duration="2000"
  //             >
  //               Acesse agora o melhor método para você poder aprender uma das
  //               profissões mais bem pagas da década mesmo se você for um
  //               completo iniciante na área:
  //             </h5>
  //           </div>
  //         </div>
  //         {/* <a href="https://pay.hotmart.com/P73855232F?checkoutMode=10"> */}
  //         <div
  //           className="mt-4 button button-first-section text-center"
  //           data-aos="zoom-in"
  //           data-aos-duration="1500"
  //           onClick={() => handleModal('btn1')}
  //         >
  //           SIM! Quero Acessar Agora!
  //         </div>
  //         {/* </a> */}
  //         <p className="text-center mt-4 pb-4 text-lp">
  //           (Aprenda mesmo com pouco tempo para praticar)
  //         </p>
  //       </div>
  //     </div>
  //     <div className="container second-section text-center col-12 pt-5 mt-3 pb-5 ">
  //       <h2
  //         className="text-center mt-4"
  //         data-aos="zoom-in"
  //         data-aos-duration="2000"
  //       >
  //         O que você vai aprender
  //       </h2>
  //       <div className="d-md-flex d-grid mt-5 pt-4 align-items-center">
  //         {/* <div className="col-md-4 col-12 mr-3 text-center mockup">
  //           <img
  //             src={mockup}
  //             alt=""
  //             width="100%"
  //             data-aos="zoom-in"
  //             className="mockup"
  //             data-aos-duration="2000"
  //           />
  //         </div> */}
  //         <div className="col-md-8 col-12  m-auto">
  //           <div className="d-flex mb-3">
  //             <img
  //               className="mr-4 pr-4"
  //               src={check}
  //               alt=""
  //               width="50px"
  //               height="50px"
  //               data-aos="zoom-in"
  //               data-aos-duration="2000"
  //             />
  //             <div className="text-left ml-4">
  //               <h5 data-aos="zoom-in" data-aos-duration="2000">
  //                 Como pensar como um programador
  //               </h5>
  //               <p
  //                 className="subtitle"
  //                 data-aos="zoom-in"
  //                 data-aos-duration="2000"
  //               >
  //                 Entenda como funciona a lógica de programação para você
  //                 conseguir construir seus próprios projetos com um método
  //                 aprovado por mais de 200 devs juniores.
  //               </p>
  //             </div>
  //           </div>
  //           <div className="d-flex mb-3">
  //             <img
  //               className="mr-4 pr-4"
  //               src={check}
  //               alt=""
  //               width="50px"
  //               height="50px"
  //               data-aos="zoom-in"
  //               data-aos-duration="2000"
  //             />
  //             <div className="text-left ml-4">
  //               <h5 data-aos="zoom-in" data-aos-duration="2000">
  //                 Como construir uma página WEB do ZERO
  //               </h5>
  //               <p
  //                 className="subtitle"
  //                 data-aos="zoom-in"
  //                 data-aos-duration="2000"
  //               >
  //                 Esta página servirá como seu primeiro portfólio que você irá
  //                 aprender a criar com base no que irei te ensinar sobre HTML e
  //                 CSS.
  //               </p>
  //             </div>
  //           </div>

  //           <div className="d-flex mb-3" id="aprendizado">
  //             <img
  //               className="mr-4 pr-4"
  //               src={check}
  //               alt=""
  //               width="50px"
  //               height="50px"
  //               data-aos="zoom-in"
  //               data-aos-duration="2000"
  //             />
  //             <div className="text-left ml-4">
  //               <h5 data-aos="zoom-in" data-aos-duration="2000">
  //                 Os fundamentos da linguagem de programação mais utilizada no
  //                 mundo atualmente
  //               </h5>
  //               <p
  //                 className="subtitle"
  //                 data-aos="zoom-in"
  //                 data-aos-duration="2000"
  //               >
  //                 Aprenda Javascript sem enrolação e crie uma ferramenta
  //                 geradora de CPF's para testar o que você aprendeu até então.
  //               </p>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //     <div className="third-section text-center col-12  pb-5 ">
  //       <h2
  //         className="container text-center pt-5 mt-3 mb-4"
  //         data-aos="zoom-in"
  //         data-aos-duration="2000"
  //       >
  //         Uma das melhores profissões da década
  //       </h2>
  //       <div className="container d-md-flex d-grid  justify-content-between mt-5 py-4 mb-5">
  //         <div
  //           className=" col-12 col-md-4 card-noticias"
  //           data-aos="zoom-in"
  //           data-aos-duration="2000"
  //         >
  //           <img src={cart} alt="" width="80px" />
  //           <h4 className="color-blue-text pt-3">R$4.472,00</h4>
  //           <p className="subtitle">
  //             Média de salário para Desenvolvedor Front-End
  //           </p>
  //           <span className="subtitle span-font">Fonte: Glassdoor</span>
  //         </div>
  //         <div
  //           className="col-12 col-md-4 card-noticias"
  //           data-aos="zoom-in"
  //           data-aos-duration="2000"
  //         >
  //           <img src={teclado} alt="" width="80px" />
  //           <h4 className="color-blue-text pt-3">234%</h4>
  //           <p className="subtitle">Média de crescimento anual do mercado</p>
  //           <span className="subtitle span-font">Fonte: Exame</span>
  //         </div>
  //         <div
  //           className="col-md-4 col-12"
  //           data-aos="zoom-in"
  //           data-aos-duration="2000"
  //         >
  //           <img src={lupa} alt="" width="80px" />
  //           <h4 className="color-blue-text pt-3">+6.000</h4>
  //           <p className="subtitle">Vagas em aberto de Front-End no LinkedIn</p>
  //           <span className="subtitle span-font">Fonte: LinkedIn</span>
  //         </div>
  //       </div>
  //     </div>
  //     <div
  //       className="container fourth-section text-center col-12  pb-5"
  //       id="capitulos"
  //     >
  //       <h2
  //         className="text-center py-4 mb-4 pt-5 mt-3"
  //         data-aos="zoom-in"
  //         data-aos-duration="2000"
  //       >
  //         Capítulos
  //       </h2>
  //       <div className="container col-12 d-md-flex d-grid ">
  //         <div className="col-12 col-md-6 capitulos">
  //           <div
  //             className="d-flex align-items-baseline mb-4"
  //             data-aos="zoom-in"
  //             data-aos-duration="2000"
  //           >
  //             <h2 className="color-blue-text mb-0 pr-2 numbers-capitulo">1</h2>
  //             <p className="mb-0 text-capitulo">Lógica de programação</p>
  //           </div>
  //           <div
  //             className="d-flex align-items-baseline mb-4"
  //             data-aos="zoom-in"
  //             data-aos-duration="2000"
  //           >
  //             <h2 className="color-blue-text mb-0 pr-2 numbers-capitulo">2</h2>
  //             <p className="mb-0 text-capitulo">Variáveis e constantes</p>
  //           </div>
  //           <div
  //             className="d-flex align-items-baseline mb-4"
  //             data-aos="zoom-in"
  //             data-aos-duration="2000"
  //           >
  //             <h2 className="color-blue-text mb-0 pr-2 numbers-capitulo">3</h2>
  //             <p className="mb-0 text-capitulo">Lógica condicional</p>
  //           </div>
  //           <div
  //             className="d-flex align-items-baseline mb-4"
  //             data-aos="zoom-in"
  //             data-aos-duration="2000"
  //           >
  //             <h2 className="color-blue-text mb-0 pr-2 numbers-capitulo">4</h2>
  //             <p className="mb-0 text-capitulo">Operadores Relacionais</p>
  //           </div>
  //           <div
  //             className="d-flex align-items-baseline mb-4"
  //             data-aos="zoom-in"
  //             data-aos-duration="2000"
  //           >
  //             <h2 className="color-blue-text mb-0 pr-2 numbers-capitulo">5</h2>
  //             <p className="mb-0 text-capitulo">Operadores Lógicos</p>
  //           </div>
  //           <div
  //             className="d-flex align-items-baseline mb-4"
  //             data-aos="zoom-in"
  //             data-aos-duration="2000"
  //           >
  //             <h2 className="color-blue-text mb-0 pr-2 numbers-capitulo">6</h2>
  //             <p className="mb-0 text-capitulo">Arrays</p>
  //           </div>
  //           <div
  //             className="d-flex align-items-baseline mb-4"
  //             data-aos="zoom-in"
  //             data-aos-duration="2000"
  //           >
  //             <h2 className="color-blue-text mb-0 pr-2 numbers-capitulo">7</h2>
  //             <p className="mb-0 text-capitulo">Objetos</p>
  //           </div>
  //           <div
  //             className="d-flex align-items-baseline mb-4"
  //             data-aos="zoom-in"
  //             data-aos-duration="2000"
  //           >
  //             <h2 className="color-blue-text mb-0 pr-2 numbers-capitulo">8</h2>
  //             <p className="mb-0 text-capitulo">Loops</p>
  //           </div>
  //           <div
  //             className="d-flex align-items-baseline mb-4"
  //             data-aos="zoom-in"
  //             data-aos-duration="2000"
  //           >
  //             <h2 className="color-blue-text mb-0 pr-2 numbers-capitulo">9</h2>
  //             <p className="mb-0 text-capitulo">Funções</p>
  //           </div>
  //           <div
  //             className="d-flex align-items-baseline mb-4"
  //             data-aos="zoom-in"
  //             data-aos-duration="2000"
  //           >
  //             <h2 className="color-blue-text mb-0 pr-2 numbers-capitulo">10</h2>
  //             <p className="mb-0 text-capitulo">Arquitetura client/server</p>
  //           </div>
  //         </div>
  //         <div className="col-12 col-md-6 capitulos">
  //           <div
  //             className="d-flex align-items-baseline mb-4"
  //             data-aos="zoom-in"
  //             data-aos-duration="2000"
  //           >
  //             <h2 className="color-blue-text mb-0 pr-2 numbers-capitulo">11</h2>
  //             <p className="mb-0 text-capitulo">Conceitos do HTML e CSS</p>
  //           </div>
  //           <div
  //             className="d-flex align-items-baseline mb-4"
  //             data-aos="zoom-in"
  //             data-aos-duration="2000"
  //           >
  //             <h2 className="color-blue-text mb-0 pr-2 numbers-capitulo">12</h2>
  //             <p className="mb-0 text-capitulo">Bootstrap v5.0</p>
  //           </div>
  //           <div
  //             className="d-flex align-items-baseline mb-4"
  //             data-aos="zoom-in"
  //             data-aos-duration="2000"
  //           >
  //             <h2 className="color-blue-text mb-0 pr-2 numbers-capitulo">13</h2>
  //             <p className="mb-0 text-capitulo">Criando seu primeiro site</p>
  //           </div>
  //           <div
  //             className="d-flex align-items-baseline mb-4"
  //             data-aos="zoom-in"
  //             data-aos-duration="2000"
  //           >
  //             <h2 className="color-blue-text mb-0 pr-2 numbers-capitulo">14</h2>
  //             <p className="mb-0 text-capitulo">Desafio final</p>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //     <div className="first-section" id="bonus">
  //       <div className="container">
  //         <h2 className="text-center py-4 mb-4">Bônus</h2>
  //         <h5 className="text-center pb-5 mb-5">
  //           Ao adquirir o E-book <strong>Dev Start</strong> você ganha esses
  //           bônus exclusivos para acelerar sua carreira e te guiar em meio a
  //           tanta oportunidade no mercado de trabalho.
  //         </h5>

  //         <div className="container col-12 d-md-flex  mb-5">
  //           <div className="col-8 bonus">
  //             <h5>Comunidade Exclusiva no Discord (Acesso vitalício)</h5>
  //             <p className="subtitle">
  //               Tenha contato comigo e minha equipe diretamente no discord, um
  //               ambiente de networking de pessoas que estão passando pelo mesmo
  //               que você e que eu também uso para tirar todas as dúvidas dos
  //               meus alunos de perto.
  //             </p>
  //           </div>
  //           <div className="col-4 justify-content-start d-flex">
  //             <div className="preco-bonus">
  //               <strike>
  //                 <span>R$497/ano</span>
  //               </strike>
  //               <p className="por">por</p>
  //               <span>R$0</span>
  //             </div>
  //           </div>
  //         </div>

  //         <div className="container col-12 d-md-flex mb-5">
  //           <div className="col-8 bonus">
  //             <h5>Guia de Carreira de Sucesso</h5>
  //             <p className="subtitle">
  //               Aprenda rapidamente linguagens de programação e habilidades
  //               técnicas para ser aprovado nos melhores empregos com os melhores
  //               salários do mercado de trabalho.
  //             </p>
  //           </div>
  //           <div className="col-4 justify-content-start d-flex">
  //             <div className="preco-bonus">
  //               <strike>
  //                 <span>R$147</span>
  //               </strike>
  //               <p className="por">por</p>
  //               <span>R$0</span>
  //             </div>
  //           </div>
  //         </div>

  //         <div className="container col-12 d-md-flex  mb-5">
  //           <div className="col-8 bonus">
  //             <h5>Perfil Campeão no Linkedin</h5>
  //             <p className="subtitle">
  //               As dicas mais efetivas que utilizo em meu linkedin para ser
  //               achada pelo Google, Nubank, Mercado Livre e outras dezenas de
  //               empresas e que irei revelar apenas para quem adquirir meu e-book
  //               hoje.
  //             </p>
  //           </div>
  //           <div className="col-4 justify-content-start d-flex">
  //             <div className="preco-bonus">
  //               <strike>
  //                 <span>R$67</span>
  //               </strike>
  //               <p className="por">por</p>
  //               <span>R$0</span>
  //             </div>
  //           </div>
  //         </div>

  //         <div className="container col-12 d-md-flex pb-5">
  //           <div className="col-8 bonus">
  //             <h5>Plano de Estudos e Modelo de Currículo Vencedor</h5>
  //             <p className="subtitle">
  //               Acesse o planner que eu utilizo para me organizar de forma
  //               efetiva e proativa. Consiga de fato a colocar o que você
  //               aprender em prática e avance na carreira. Tenha também acesso ao
  //               meu modelo de currículo de sucesso para ser aprovado (a) nas
  //               melhores vagas de emprego
  //             </p>
  //           </div>
  //           <div className="col-4 justify-content-start d-flex">
  //             <div className="preco-bonus">
  //               <strike>
  //                 <span>R$47</span>
  //               </strike>
  //               <p className="por">por</p>
  //               <span>R$0</span>
  //             </div>
  //             {/* <img src={price_plano} alt="" width="150px" /> */}
  //           </div>
  //         </div>

  //         <div className="text-center pb-5 valor-bonus">
  //           <p className="bonus-value">
  //             Mais de R$758 em BÔNUS EXCLUSIVOS (de graça para você)
  //           </p>
  //           <br />
  //           <p className="subtitle-bonus">
  //             Bônus válidos para os dias {date3}, {date2}, {date1} (Isso termina
  //             HOJE!)
  //           </p>
  //           {/* <a href="https://pay.hotmart.com/P73855232F?checkoutMode=10"> */}
  //           <div
  //             className="mt-4 button button-first-section text-center mb-5"
  //             data-aos="zoom-in"
  //             data-aos-duration="2000"
  //             onClick={() => handleModal('btn2')}
  //           >
  //             Quero Meus Bônus Agora!
  //           </div>
  //           {/* </a> */}
  //           <p className="condicoes">
  //             *Os bônus são enviados por e-mail após 7 dias da compra.
  //           </p>
  //         </div>
  //       </div>
  //     </div>
  //     <div className="sixteen-section   pb-5 pt-3">
  //       <h2
  //         className="container text-center  pt-5 mt-4"
  //         data-aos="zoom-in"
  //         data-aos-duration="2000"
  //       >
  //         Condição especial
  //       </h2>
  //       <p
  //         className="subtitle text-center pb-4 mb-4"
  //         data-aos="zoom-in"
  //         data-aos-duration="2000"
  //       >
  //         Pré Venda
  //       </p>
  //       <h5
  //         className="text-center pb-5 mb-5"
  //         data-aos="zoom-in"
  //         data-aos-duration="2000"
  //       >
  //         Os lugares destinados à essa promoção são limitados e o valor pode
  //         subir a qualquer momento.
  //       </h5>
  //       <div className="text-center">
  //         <img
  //           src={price}
  //           alt=""
  //           width="50%"
  //           data-aos="zoom-in"
  //           className="img-price"
  //           data-aos-duration="2000"
  //         />
  //       </div>
  //       {/* <a href="https://pay.hotmart.com/P73855232F?checkoutMode=10"> */}
  //       <div
  //         className="mt-4 button button-first-section text-center mb-5"
  //         data-aos="zoom-in"
  //         data-aos-duration="2000"
  //         onClick={() => handleModal('btn3')}
  //       >
  //         Garanta seu desconto aqui
  //       </div>
  //       {/* </a> */}
  //     </div>
  //     <div className="third-section   pb-2 pt-4">
  //       <h2
  //         className="text-center py-4 mb-4 pt-5 mt-3"
  //         data-aos="zoom-in"
  //         data-aos-duration="2000"
  //       >
  //         Risco Zero{' '}
  //       </h2>

  //       <div className="container d-md-flex col-10 align-items-center m-auto pb-5">
  //         <div className="col-md-7 col-12">
  //           <p className="subtitle" data-aos="zoom-in" data-aos-duration="2000">
  //             Imprevistos acontecem, então decidi proteger o seu investimento
  //             por até 7 dias! Caso se arrependa, basta falar com nossa equipe
  //             que devolveremos o seu dinheiro. Sem central de atendimento e sem
  //             pegadinha!
  //           </p>
  //         </div>
  //         <div className="col-md-5 col-12">
  //           <div className="text-center garantia">
  //             <img
  //               src={garantia}
  //               alt=""
  //               width="40%"
  //               data-aos="zoom-in"
  //               data-aos-duration="2000"
  //             />
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //     <div className="third-section  mb-5 pb-5 pt-4">
  //       <h2
  //         className="text-center py-4 mb-4 pt-5 mt-3"
  //         data-aos="zoom-in"
  //         data-aos-duration="2000"
  //       >
  //         Depoimentos de quem já comprou
  //       </h2>

  //       <div className="container d-md-flex col-10 align-items-center m-auto pb-5">
  //         <div className="col-md-4 col-12 pb-4">
  //           <img src={depoimento1} alt="" width={'100%'} />
  //         </div>
  //         <div className="col-md-4  col-12 pb-4">
  //           <img src={depoimento2} alt="" width={'100%'} />
  //         </div>
  //         <div className="col-md-4  col-12 pb-4">
  //           <img src={depoimento3} alt="" width={'100%'} />
  //         </div>
  //       </div>
  //     </div>
  //     <div className="  mb-5 pb-3 pt-5 mt-3">
  //       <h2
  //         className="text-center py-4 mb-5"
  //         data-aos="zoom-in"
  //         data-aos-duration="2000"
  //       >
  //         Quem é Alexia Kattah
  //       </h2>

  //       <div className="container d-md-flex col-10 align-items-center m-auto">
  //         <div className="col-md-7 col-12">
  //           <p
  //             className="subtitle pr-5"
  //             data-aos="zoom-in"
  //             data-aos-duration="2000"
  //           >
  //             Além de Senior Software Engineer, Alexia é Engenheira da
  //             Computação e também é CTO e sócia na OQ Digital (startup
  //             brasileira de tecnologia e marketing digital), palestrante e
  //             freelancer. Em sua carreira de mais de 5 anos na programação já
  //             realizou dezenas de projetos como freelancer tanto como dev
  //             front-end, dev back-end e mobile. Hoje comanda uma equipe de
  //             tecnologia na sua empresa e lá atua como desenvolvedora FullStack
  //             Sênior.
  //           </p>

  //           <p
  //             className="subtitle pr-5"
  //             data-aos="zoom-in"
  //             data-aos-duration="2000"
  //           >
  //             Apesar disso, Alexia tem como missão principal ajudar futuros devs
  //             a conseguirem seu primeiro emprego no mercado de trabalho. Seus
  //             alunos e mentorados vão desde pessoas iniciantes na área até
  //             desenvolvedores avançados.
  //           </p>
  //         </div>
  //         <div
  //           className="col-md-5 col-12"
  //           data-aos="zoom-in"
  //           data-aos-duration="2000"
  //         >
  //           <div className="text-center">
  //             <img src={alexia} alt="" width="70%" />
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //     <div className="third-section  mb-5 pb-5 pt-4" id="faq">
  //       <h2
  //         className="text-center py-4 mb-4"
  //         data-aos="zoom-in"
  //         data-aos-duration="2000"
  //       >
  //         Perguntas frequentes{' '}
  //       </h2>

  //       {/* <Accordion defaultActiveKey="0" className="accordion">
  //         <Accordion.Item
  //           eventKey="0"
  //           className=""
  //           data-aos="zoom-in"
  //           data-aos-duration="2000"
  //         >
  //           <Accordion.Header>Para quem é esse e-book?</Accordion.Header>
  //           <Accordion.Body>
  //             O material contido no método Dev Start foi preparado para quem é
  //             iniciante na programação e está começando a desbravar as
  //             tecnologias do mundo Front-End e desenvolvimento WEB. Então, se
  //             você começou há pouco tempo a estudar sobre o assunto, não se
  //             preocupe, o método Dev Start será seu melhor guia que irá te
  //             poupar tempo para aprender o arroz com feijão da forma certa ao
  //             invés de ficar pulando de galho em galho em vídeos desconexos no
  //             youtube ou através de conteúdos de blogs jogados na Internet.
  //           </Accordion.Body>
  //         </Accordion.Item>
  //         <Accordion.Item
  //           eventKey="1"
  //           className="container d-md-flex col-12 align-items-center m-auto pb-2"
  //           data-aos="zoom-in"
  //           data-aos-duration="2000"
  //         >
  //           <Accordion.Header> Vou ter suporte?</Accordion.Header>
  //           <Accordion.Body>
  //             Sim, o suporte é feito diretamente comigo onde você poderá tirar
  //             suas dúvidas no meu canal VIP do discord que eu libero o acesso
  //             apenas para quem adquirir meu método hoje! Você também pode mandar
  //             suas dúvidas para meu e-mail: contato@alexiakattah.com.br que vai
  //             ser um prazer te ajudar! Além disso, estou disponível em meu
  //             Instagram{' '}
  //             <a href="https://www.instagram.com/alexiakattah/">
  //               @alexiakattah
  //             </a>{' '}
  //             {/* e no <a href="https://www.tiktok.com/@alexiakattah">TikTok</a>{' '} */}
  //       {/* para te auxiliar em algo que não esteja claro sobre meu método Dev
  //             Start.
  //           </Accordion.Body>
  //         </Accordion.Item>
  //         <Accordion.Item
  //           eventKey="2"
  //           className="container d-md-flex col-12 align-items-center m-auto pb-2"
  //           data-aos="zoom-in"
  //           data-aos-duration="2000"
  //         >
  //           <Accordion.Header>
  //             Vou conseguir colocar em prática o que eu for aprender?
  //           </Accordion.Header>
  //           <Accordion.Body>
  //             Absolutamente sim! No método Dev Start escrevi tudo muito
  //             passo-a-passo, desde os primeiros capítulos do e-book (onde você
  //             irá aprender como um código funciona através da compreensão fácil
  //             e descomplicada da lógica de programação) até a criação dos seus
  //             primeiros projetos WEB. Todos os capítulos foram estruturados de
  //             forma que você esteja sempre aplicando aquilo que aprendeu através
  //             de exemplos e exercícios práticos!
  //           </Accordion.Body>
  //         </Accordion.Item>
  //         <Accordion.Item
  //           eventKey="3"
  //           className="container d-md-flex col-12 align-items-center m-auto pb-2"
  //           data-aos="zoom-in"
  //           data-aos-duration="2000"
  //         >
  //           <Accordion.Header>
  //             {' '}
  //             Porque eu preciso aprender a programar?
  //           </Accordion.Header>
  //           <Accordion.Body>
  //             Ao desenvolver essa habilidade de programar em Front-End e a criar
  //             projetos WEB, você estará criando novas conexões cognitivas no seu
  //             cérebro o que é crucial para que você consiga pensar melhor,
  //             resolver problemas de forma mais rápida, te ajudar a ser uma
  //             pessoa mais criativa e conseguir ser mais persistente através da
  //             melhoria da capacidade de superação. Na verdade, todo mundo
  //             deveria aprender essa nova alfabetização da nossa era pois com ela
  //             você desenvolve diversas áreas da sua vida!
  //           </Accordion.Body>
  //         </Accordion.Item>
  //       </Accordion> */}

  //       <div
  //         className="container d-md-flex col-12 align-items-center m-auto pb-2"
  //         data-aos="zoom-in"
  //         data-aos-duration="2000"
  //       >
  //         <div className="width-100">
  //           <Button
  //             onClick={() => setOpen(!open)}
  //             aria-controls="text1"
  //             aria-expanded={open}
  //             className="card-questions "
  //           >
  //             Para quem é esse e-book?
  //           </Button>
  //           <Collapse in={open}>
  //             <div id="text1 " className="pt-3">
  //               <p className="faq-questions">
  //                 O material contido no método Dev Start foi preparado para quem
  //                 é iniciante na programação e está começando a desbravar as
  //                 tecnologias do mundo Front-End e desenvolvimento WEB.
  //               </p>
  //               <p className="faq-questions">
  //                 Então, se você começou há pouco tempo a estudar sobre o
  //                 assunto, não se preocupe, o método Dev Start será seu melhor
  //                 guia que irá te poupar tempo para aprender o arroz com feijão
  //                 da forma certa ao invés de ficar pulando de galho em galho em
  //                 vídeos desconexos no youtube ou através de conteúdos de blogs
  //                 jogados na Internet.
  //               </p>
  //             </div>
  //           </Collapse>
  //         </div>
  //       </div>
  //       <div
  //         className="container d-md-flex col-12 align-items-center m-auto pb-2"
  //         data-aos="zoom-in"
  //         data-aos-duration="2000"
  //       >
  //         <div className="width-100 ">
  //           <Button
  //             onClick={() => setOpen1(!open1)}
  //             aria-controls="text1"
  //             aria-expanded={open1}
  //             className="card-questions"
  //           >
  //             Vou ter suporte?
  //           </Button>
  //           <Collapse in={open1}>
  //             <div id="text1" className="pt-3">
  //               Sim, o suporte é feito diretamente comigo onde você poderá tirar
  //               suas dúvidas no meu canal VIP do discord que eu libero o acesso
  //               apenas para quem adquirir meu método hoje! Você também pode
  //               mandar suas dúvidas para meu e-mail: contato@alexiakattah.com.br
  //               que vai ser um prazer te ajudar! Além disso, estou disponível em
  //               minhas redes sociais no Instagram{' '}
  //               <a href="https://www.instagram.com/alexiakattah/">
  //                 @alexiakattah
  //               </a>{' '}
  //               para te auxiliar em algo que não esteja claro sobre meu método
  //               Dev Start.
  //             </div>
  //           </Collapse>
  //         </div>
  //       </div>
  //       <div
  //         className="container d-md-flex col-12 align-items-center m-auto pb-2"
  //         data-aos="zoom-in"
  //         data-aos-duration="2000"
  //       >
  //         <div className="width-100 ">
  //           <Button
  //             onClick={() => setOpen2(!open2)}
  //             aria-controls="text1"
  //             aria-expanded={open2}
  //             className="card-questions"
  //           >
  //             Vou conseguir colocar em prática o que eu for aprender?
  //           </Button>
  //           <Collapse in={open2}>
  //             <div id="text1" className="pt-3">
  //               <p className="faq-questions">
  //                 Absolutamente sim! No método Dev Start escrevi tudo muito
  //                 passo-a-passo, desde os primeiros capítulos do e-book (onde
  //                 você irá aprender como um código funciona através da
  //                 compreensão fácil e descomplicada da lógica de programação)
  //                 até a criação dos seus primeiros projetos WEB.
  //               </p>
  //               <p className="faq-questions">
  //                 Todos os capítulos foram estruturados de forma que você esteja
  //                 sempre aplicando aquilo que aprendeu através de exemplos e
  //                 exercícios práticos!
  //               </p>
  //             </div>
  //           </Collapse>
  //         </div>
  //       </div>

  //       <div
  //         className="container d-md-flex col-12 align-items-center m-auto pb-2"
  //         data-aos="zoom-in"
  //         data-aos-duration="2000"
  //       >
  //         <div className="width-100">
  //           <Button
  //             onClick={() => setOpen3(!open3)}
  //             aria-controls="text1"
  //             aria-expanded={open3}
  //             className="card-questions"
  //           >
  //             Já efetuei o pagamento, qual o próximo passo?
  //           </Button>
  //           <Collapse in={open3}>
  //             <div id="text1" className="pt-3">
  //               Assim que sua compra for confirmada, você receberá no seu e-mail
  //               o acesso a plataforma Hotmart onde estará hospedado todo o
  //               material e que você poderá acessar tranquilamente. Além disso,
  //               após a confirmação da compra, irei te enviar no e-mail o acesso
  //               imediato à comunidade Dev Start onde você poderá usar para
  //               entrar em contato comigo e minha equipe e tirar suas dúvidas
  //               sobre programação.
  //             </div>
  //           </Collapse>
  //         </div>
  //       </div>

  //       <div
  //         className="container d-md-flex col-12 align-items-center m-auto pb-2"
  //         data-aos="zoom-in"
  //         data-aos-duration="2000"
  //       >
  //         <div className="width-100">
  //           <Button
  //             onClick={() => setOpen4(!open4)}
  //             aria-controls="text1"
  //             aria-expanded={open4}
  //             className="card-questions"
  //           >
  //             Porque eu preciso aprender a programar?
  //           </Button>
  //           <Collapse in={open4}>
  //             <div id="text1" className="pt-3">
  //               Ao desenvolver essa habilidade de programar em Front-End e a
  //               criar projetos WEB, você estará criando novas conexões
  //               cognitivas no seu cérebro o que é crucial para que você consiga
  //               pensar melhor, resolver problemas de forma mais rápida, te
  //               ajudar a ser uma pessoa mais criativa e conseguir ser mais
  //               persistente através da melhoria da capacidade de superação. Na
  //               verdade, todo mundo deveria aprender essa nova alfabetização da
  //               nossa era pois com ela você desenvolve diversas áreas da sua
  //               vida!
  //             </div>
  //           </Collapse>
  //         </div>
  //       </div>
  //     </div>

  //     <div className="first-section" id="bonus">
  //       <div className="container">
  //         <h2 className="text-center py-5 mb-4">
  //           Caso você tenha rolado a página até aqui e apenas quer saber do que
  //           isso se trata, veja só:
  //         </h2>
  //         <h5 className="text-align-left pb-3 mb-3">
  //           Este é o meu e-book “Dev-Start” onde eu ensino o essencial para você
  //           começar na programação hoje.
  //         </h5>
  //         <h5 className="text-align-left pb-3 mb-3">
  //           Com ele você vai aprender os fundamentos do desenvolvimento
  //           Front-End com base em HTML e CSS e na linguagem de programação mais
  //           usada no mundo atualmente, o Javascript.
  //         </h5>
  //         <h5 className="text-align-left pb-3 mb-3">
  //           Com meu método que você irá acessar hoje, você finalmente irá:
  //         </h5>
  //         <h5 className="text-align-left pb-3 mb-3">
  //           ✅ Aprender os fundamentos de uma das profissões mais{' '}
  //           <strong>requisitadas</strong> e mais <strong>bem pagadas</strong> da
  //           atualidade;
  //         </h5>
  //         <h5 className="text-align-left pb-3 mb-3">
  //           ✅ Criar <strong>projetos de desenvolvimento WEB</strong> já nas
  //           primeiras semanas;
  //         </h5>
  //         <h5 className="text-align-left pb-3 mb-3">
  //           ✅ Aumentar as chances de conquistar um <strong>emprego</strong> na
  //           área tech com um <strong>ótimo salário</strong> mesmo sem estágio na
  //           área;
  //         </h5>

  //         <h5 className="text-align-left pb-3 mb-3">
  //           ✅ Conseguir fazer uma <strong>transição de carreira</strong> sem
  //           dor de cabeça;
  //         </h5>
  //         <h5 className="text-align-left pb-3 mb-3">
  //           E, não importa se você está perdido em meio a tantas informações na
  //           internet.
  //         </h5>
  //         <h5 className="text-align-left pb-3 mb-3">
  //           Não importa se você está com dificuldade sobre qual linguagem de
  //           programação escolher.
  //         </h5>
  //         <h5 className="text-align-left pb-3 mb-3">
  //           Irei te mostrar o caminho mais rápido e prático para você aplicar
  //           tudo que eu ensino no meu livro sem dificuldade nenhuma.
  //         </h5>
  //         <h5 className="text-align-left pb-3 mb-3">
  //           Talvez você esteja pensando que eu vou te cobrar uma fortuna por
  //           tudo isso e, eu confesso uma coisa, você está enganado!
  //         </h5>
  //         <h5 className="text-align-left pb-3 mb-3">
  //           Na verdade: o meu E-book e todos os 5 bônus que liberei somam ao
  //           total R$ 905.
  //         </h5>
  //         <h5 className="text-align-left pb-3 mb-3">
  //           <strong>
  //             Mas, somente hoje você tem a oportunidade de garantir tudo isso
  //             por apenas R$ 97 🥳
  //           </strong>
  //         </h5>

  //         <h5 className="text-align-left pb-3 mb-3">
  //           E o mais legal disso tudo é que você tem 7 dias para estudar o
  //           material e, caso não gostar, eu te devolvo todo o dinheiro
  //           investido.
  //         </h5>

  //         <h5 className="text-align-left pb-3 mb-3">
  //           Portanto, você pode clicar no botão abaixo e já garantir o seu
  //           agora!
  //         </h5>

  //         <div className="text-center pb-5 valor-bonus">
  //           {/* <a href="https://pay.hotmart.com/P73855232F?checkoutMode=10"> */}
  //           <div
  //             className="mt-4 button button-first-section text-center mb-1"
  //             data-aos="zoom-in"
  //             data-aos-duration="2000"
  //             onClick={() => handleModal('btn_after_faq')}
  //           >
  //             SIM! QUERO GARANTIR AGORA ➡️
  //           </div>
  //           {/* </a> */}
  //         </div>
  //       </div>
  //     </div>

  //     <div className="d-flex m-auto pt-5 justify-content-center mb-5 ">
  //       <img
  //         src={logo}
  //         alt=""
  //         width={'200px'}
  //         onClick={() => handleModal('logo-footer')}
  //       />
  //     </div>
  //     <div className="footer container d-flex justify-content-between ">
  //       <p className="subtitle-footer footer-direitos">
  //         Dev Start © 2023. Todos os direitos reservados
  //       </p>
  //       <div className="d-flex">
  //         <a
  //           href="https://firebasestorage.googleapis.com/v0/b/links-ca974.appspot.com/o/Pol%C3%ADtica%20de%20Privacidade%20-%20Dev%20Start.pdf?alt=media&token=8ba0b729-6153-4cda-9f6b-b46f1396fca7"
  //           target="_blank"
  //           rel="noreferrer"
  //         >
  //           <p className="subtitle-footer mr-3">Política de Privacidade</p>
  //         </a>
  //         <a
  //           href="https://firebasestorage.googleapis.com/v0/b/links-ca974.appspot.com/o/Pol%C3%ADtica%20de%20Termos%20de%20Uso%20-%20Dev%20Start.pdf?alt=media&token=b5382bbe-1431-440a-bb02-a78fc6c91eff"
  //           target="_blank"
  //           rel="noreferrer"
  //         >
  //           <p className="subtitle-footer">Termos de Uso</p>
  //         </a>
  //       </div>
  //       {modal()}
  //     </div>
  //   </>
  // );
}

export default DevStart;
