import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import unlock from '../../assets/open-padlock.png';
import lock from '../../assets/fechadura.png';

import { Card } from '../../components/Card';
import { Form } from '../../components/Form';

export function CursoGithub2() {
  useEffect(() => {
    AOS.init({
      once: true,
    });
  }, []);

  return (
    <>
      <div className="d-md-flex container m-auto col-12">
        <div
          className=" mt-5  video-container-curso container col-12 col-md-9 div-curso "
          data-aos="fade-up"
        >
          <iframe
            src="https://www.youtube.com/embed/Rs5fXF_BhWc?controls=0"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
        <div className="col-md-3 mt-5 videos-curso">
          <div className="conteudos">
            <h1>Conteúdos</h1>
          </div>
          <a href="/workshop-git-github/7b753893-fc4c-4032-a0f9-576e078bb995">
            <div className="aula">
              <div className="d-flex cadeado">
                <h2>Aula 1</h2>
                <img src={unlock} alt="" />
              </div>
              <p>
                Nessa aula você vai aprender a criar sua conta, instalar e
                configurar o ambiente.
              </p>
            </div>
          </a>
          <a href="/workshop-git-github/b635eb17-2c4a-4f6d-b94a-fd37ca49ac18">
            <div className="aula ">
              <div className="d-flex cadeado">
                <h2>Aula 2</h2>
                <img src={unlock} alt="" />
              </div>
              <p>
                Nessa aula você vai aprender os principais comandos do git em um
                ambiente de produção.
              </p>
            </div>
          </a>
        </div>
      </div>

      <div className="text-center mt-4">
        <p>Alexia Kattah</p>
      </div>
    </>
  );
}
