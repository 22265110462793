import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import unlock from '../../assets/open-padlock.png';
import lock from '../../assets/fechadura.png';

import { Card } from '../../components/Card';
import { Form } from '../../components/Form';

export function CursoGithub() {
  useEffect(() => {
    AOS.init({
      once: true,
    });
  }, []);

  return (
    <>
      <div className="d-md-flex container m-auto col-12">
        <div
          className=" mt-5  video-container-curso container col-12 col-md-9 div-curso "
          data-aos="fade-up"
        >
          <iframe
            src="https://www.youtube.com/embed/URK89kExlL8?controls=0"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
        <div className="col-md-3 mt-5 videos-curso">
          <div className="conteudos">
            <h1>Conteúdos</h1>
          </div>
          <div className="aula">
            <div className="d-flex cadeado">
              <h2>Aula 1</h2>
              <img src={unlock} alt="" />
            </div>
            <p>
              Nessa aula você vai aprender a criar sua conta, instalar e
              configurar o ambiente.
            </p>
          </div>
          <div className="aula aula-2">
            <div className="d-flex cadeado">
              <h2>Aula 2</h2>
              <img src={lock} alt="" />
            </div>
            <p>
              Nessa aula você vai aprender os principais comandos do git em um
              ambiente de produção.
            </p>
          </div>
        </div>
      </div>

      <div className="text-center mt-4">
        <p>Alexia Kattah</p>
      </div>
    </>
  );
}
